var huawei = huawei || {};
typeof module != "undefined" && (module.exports = huawei);

// html标签增加特定class, attribute，限定样式，兼容浏览器用
document.documentElement.className += ' ie' + document.documentMode;
document.documentElement.setAttribute("data-userAgent", navigator.userAgent);

$(function ($) {
	huawei.playerLoad();
	huawei.mobilePlayerInit();
});
var js_path_cdn = "/Assets/corp/js/";
huawei.playerSetup = function () {

	// bof jwplayer
	(function ($) {
		// $(function ($) {
		// bof dom ready
		var playerInstance = null;

		function pauseVideo(e) {
			try {
				if (playerInstance)
					playerInstance.remove();
			} catch (_e) { }
		}

		$(document).off("click vclick", ".js_video_player, .js-play-btn").on("click", ".js_video_player, .js-play-btn", function (e) {
			if (typeof videojs == "undefined") {
				if (window.console) console.log("player 还未加载");
				return false;
			}
			var playerid = $(this).attr("data-player-id") || 'playerContainer';
			var video_path = $(this).attr("data-video-path");
			var video_name = $(this).attr("data-video-name"); // 增加视频名称
			if (video_name == "" || video_name == undefined) {
				video_name = video_path; //视频名称为空，去取path
			}
			var autostart = ($(this).attr("data-player-autostart") || "1") == "1";
			var ismobile = isMobile();
			if (ismobile)
				video_path = $(this).attr("data-video-path-mobile") || video_path;

			if (video_path && video_path.indexOf("//") == 0)
				video_path = location.protocol + video_path;

			var player_options = $(this).data("option") || $(this).data("options") || {};
			player_options["sources"] = player_options["sources"] || [];
			player_options["gaEvent"] = $(this).attr("data-onclick");
			var video_sources = [];
			var vlist = $(this).attr("data-video-sources");
			if (vlist) {
				vlist = vlist.split(",");
				for (var i = 0; i < vlist.length; i++) {
					var v_info = vlist[i].split("|");
					var label = $.trim(v_info[1] || "default");
					video_sources[i] = { file: $.trim(v_info[0]), label: label };
					video_sources[i]["src"] = video_sources[i]["file"];
					if (!ismobile && label.indexOf("720") >= 0) video_sources[i]["default"] = true;
					else if (ismobile && label.indexOf("360") >= 0) video_sources[i]["default"] = true;
				}

				if (ismobile) {
					var md = $.grep(video_sources, function (v) { return v["default"]; });
					if (!md.length) video_sources[video_sources.length - 1]["default"] = true;
				}

			}


			player_options["sources"] = video_sources.concat(player_options["sources"]);
			if (player_options["sources"].length == 0 && video_sources.length > 0)
				player_options["sources"] = video_sources;
			else if (video_path && video_sources.length == 0 && player_options["sources"].length == 0)
				player_options["sources"] = [{ "src": video_path, label: "default" }];
			else if (!video_path && video_sources.length == 0 && player_options["sources"].length == 0 && $(this).attr("data-url"))
				player_options["sources"] = [{ "src": $(this).attr("data-url"), label: "default" }];


			if (player_options["sources"].length > 1) {
				var sourceObj = {};
				$.each(player_options["sources"], function (i, o) {
					sourceObj[o.label.toLocaleLowerCase()] = o;
				});

				var defaultV = ismobile ? sourceObj["360p"] : sourceObj["720p"];
				if (!defaultV) {
					defaultV = player_options["sources"][0];
				}

				defaultV["selected"] = true;
			}

			player_options["poster"]= player_options["poster"] || $(this).attr("data-img-path");
			player_options["autoplay"] = typeof player_options["autoplay"] == "boolean" ? player_options["autoplay"] : autostart;
			
			if ($(this).attr("data-play-nopop")) {
				//$(this).next("img").fadeOut();
				playerInstance = initPlayer(playerid, player_options);

				$(this).addClass("invisible");
				return false;
			}

			var play_html = $('<div id="videojs_player_wrapper"><div id="' + playerid + '"/></div>');
			var $t = $(this);
			BootstrapDialog.show({
				message: play_html,
				cssClass: "video-dialog",
				onshown: function () {
					playerInstance = initPlayer(playerid, player_options);
					playerInstance.onFullscreen = function (e) {
						if (window.console) console.log(e);
					};
					setTimeout(function () {
						$(".bootstrap-dialog.video-dialog").off("keyup");
						// if (!ismobile)
						// 	playerInstance && playerInstance.play(true);
					}, 2000);
				},
				onhide: function (dialogRef) {
					//playerInstance && playerInstance.play(false);// && playerInstance.remove();
					//alert('Dialog is popping down, its message is ' + dialogRef.getMessage());
					playerInstance && playerInstance.dispose();
				}
			});

			return false;
		});

		// eof dom ready
		// });
	})(jQuery);
	// eof

};

huawei.playerLoad = function (invokeSetup) {
	jQuery.loadScript = function (url, options) {

		// Allow user to set any option except for dataType, cache, and url
		options = $.extend(options || {}, {
			dataType: "script",
			cache: true,
			url: url
		});
	
		// Use $.ajax() since it is more flexible than $.getScript
		// Return the jqXHR object so we can chain callbacks
		return jQuery.ajax(options);
	};
	function getVideoJs5() {
		$('<link rel="stylesheet" href="//www.huawei.com/Assets/public/js/vendor/video-js-5.20.5/video-js.min.css" />').prependTo("head");
		return jQuery.loadScript("//www.huawei.com/Assets/public/js/vendor/video-js-5.20.5/ie8/videojs-ie8.min.js")
			.then(function (d) {
				return jQuery.loadScript("//www.huawei.com/Assets/public/js/vendor/video-js-5.20.5/video.min.js");
			})
			.then(function (d) {
				videojs.options.flash.swf = "//www.huawei.com/Assets/public/js/vendor/video-js-5.20.5/video-js.swf";
				jQuery.loadScript("//cdnjs.cloudflare.com/ajax/libs/videojs-ga/0.4.2/videojs.ga.min.js");
				return jQuery.loadScript("//www.huawei.com/Assets/public/js/vendor/video-js-5.20.5/videojs-resolution-switcher.min.js");
			});
	}
	
	function getVideoJs7() {
		$('<link rel="stylesheet" href="//www.huawei.com/Assets/public/js/vendor/video-js-7.5.4/video-js.min.css" /><link rel="stylesheet" href="//unpkg.com/videojs-vjsdownload@1.0.4/dist/videojs-vjsdownload.css" />').prependTo("head");
		return jQuery.loadScript("//www.huawei.com/Assets/public/js/vendor/video-js-7.5.4/video.min.js")
			.then(function (d) {
				jQuery.loadScript("//cdnjs.cloudflare.com/ajax/libs/videojs-ga/0.4.2/videojs.ga.min.js");
				jQuery.loadScript("//unpkg.com/videojs-vjsdownload@1.0.4/dist/videojs-vjsdownload.min.js");
				return jQuery.loadScript("//www.huawei.com/Assets/public/js/vendor/video-js-7.5.4/silvermine-videojs-quality-selector.min.js");
			});
	}
	
	invokeSetup= (typeof invokeSetup == "boolean" && invokeSetup) || true;
	var ismobile = isMobile();
	var ie = isIE89();

/* 	var $player_flag = $(".js_video_player, .js-play-btn, .js-player-box");
	if (!$player_flag.length) {
		return;
	}
 */
	if (typeof BootstrapDialog == "undefined") {
		$('<link rel="stylesheet" href="//www.huawei.com/Assets/corp/minisite/js/vendor/bootstrap3-dialog/bootstrap-dialog.min.css">');
		jQuery.loadScript("//www.huawei.com/Assets/corp/minisite/js/vendor/bootstrap3-dialog/bootstrap-dialog.min.js");
	}

	var videoFn = ie ? getVideoJs5 : getVideoJs7;

	if (typeof videojs != "undefined") {
		videoFn = function () {
			var dfd = jQuery.Deferred();
			setTimeout(function () {
				dfd.resolve("ok");
			}, 10);
			return dfd;;
		};
	}

	var something = (function () {
		var executed = false;
		return function () {
			if (!executed) {
				executed = true;
				// do something

				videoFn().done(function (d) {
					if(!invokeSetup) return;
					huawei.playerSetup();
					if (ismobile)
						setTimeout(function () {
							$(".js_video_player").filter("[data-player-mobile-init=1]").attr("data-player-autostart", "0").trigger("click");
						}, 50);
				});


			}
		};
	})();
	function load_jwplayer_fn() {
		something();
		return;
		var noplayer = true;

		$player_flag.each(function (i, o) {
			if ($.inviewport($(o), { threshold: 10 })) {
				noplayer = false;
				return false;
			}
		});
		if (noplayer && !ismobile) return;
		$(window).off("scroll.jwplayer");
		something();
	}
	// $(window).on("scroll.jwplayer", huawei.throttle(load_jwplayer_fn, 100));
	load_jwplayer_fn();
};

huawei.mobilePlayerInit = function () {
	if (!isMobile())
		return;
	window.mobVideoFlag = true;
	// $(".js_video_player").filter("[data-player-id]").attr("data-player-autostart", "0").trigger("click");


	$(".js_video_player").each(function (i, o) {
		if ($(this).attr('run_at_pc_module')) {
			return;
		}
		var player_id = "js-player-" + $.uuid();
		var player_placeholder = '<div class="player-outer-box"><a id="' + player_id + '"></a></div>';
		$(this).attr("data-player-id", player_id).attr("data-play-nopop", 1).attr("href", "javascript:void(0)")
			.parent().prepend(player_placeholder);
		$(this).attr("data-onclick", $(this).attr("onclick")).prop("onclick", null);
	});
};

window.initPlayer = function (playerid, options) {
	options = options || {};
	$('<div class="videojs-container"><video data-setup="{}" playsinline id="' + playerid + '" class="vjs-big-play-centered video-js  video-player center-block"></video></div>')
	.replaceAll($("#" + playerid));
	var player_options = {
		controls: true,
		autoplay: true,
		preload: 'auto',
		"fluid": true,
		sources: [],
		aspectRatio: "16:9",
		muted: false,
		plugins:{}
	};
	options["aspectRatio"] = options["aspectRatio"] || options["aspectratio"] || "16:9";
	options["muted"] = options["muted"] || options["mute"] || false;
	options["loop"] = options["loop"] || options["repeat"] || false;

	if (isIE89()) {
		player_options.plugins["videoJsResolutionSwitcher"] = {
			"default": 'low', // Default resolution [{Number}, 'low', 'high'],
			dynamicLabel: true
		};
		player_options["techOrder"]=["flash", "html5"];
	}

	if (videojs.getPlugin && videojs.getPlugin("vjsdownload")) {
		player_options.plugins["vjsdownload"] =
			{
				beforeElement: 'playbackRateMenuButton',
				textControl: 'Download video',
				name: 'downloadButton',
				//downloadURL: 'https://insert_source_here.mp4' //optional if you need a different download url than the source
			};
	}

	$.extend(player_options, options);
	
	var player = videojs.getPlayers()[playerid];
	if(player) player.dispose();

	var player = videojs(playerid, player_options);


	if (videojs.getComponent('QualitySelector')) {
		player.controlBar.addChild('QualitySelector', {
			text: 'Quality',
		});
	}
	if (player.ga)
		player.ga();

	if (player_options.gaEvent) {
		var flag = false;
		var evt = function (e) {
			if (flag) return;
			flag = true;
			var gaEvent = new Function('e', player_options.gaEvent);
			gaEvent.apply(e)
		};
		if (player.one)
			player.one("play", evt);
		else
			player.on("play", evt);
	}

	var hasSend = false;
	player.on('play', function (e) {
		if (hasSend)
			return;
		hasSend = true;
		var url = player.currentSrc();
		try {
			utag.link({
				"tealium_event": "video_clicked",
				"video_id": url,
				"video_name": ""
			});
		} catch (e) {
		}
	});

	return player;
};



function isIE89() {
	var ie = /msie ([6-9]|10)/i.test(navigator.userAgent);
	return ie;
}

function is_touch_device() {
	return 'ontouchstart' in window        // works on most browsers 
		|| navigator.maxTouchPoints;       // works on IE10/11 and Surface
};
function isMobile() {
	var isMobile = {
		Android: function () {
			return navigator.userAgent.match(/Android/i);
		},
		BlackBerry: function () {
			return navigator.userAgent.match(/BlackBerry/i);
		},
		iOS: function () {
			return navigator.userAgent.match(/iPhone|iPad|iPod/i);
		},
		Opera: function () {
			return navigator.userAgent.match(/Opera Mini/i);
		},
		Windows: function () {
			return navigator.userAgent.match(/IEMobile/i);
		},
		any: function () {
			return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
		}
	};
	return isMobile.any();
}


/**
 * jQuery UUID plugin 1.0.0
 *
 * @author Eugene Burtsev
 */
(function ($) {
	$.uuid = function () {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	};
})(jQuery);